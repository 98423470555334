
import PropTypes from "prop-types"
import React from "react"
import ContactForm from "./Form"



const Header = ({ pageHeader, className, subheader }) => (
  <header>
    <div className={`${className}`}>
      <div className="container">
        <div className="row">
          <div className='hero col-lg-5 mb-3 text-center text-lg-left'>
            <h1 className="pt-2 pb-lg-3">{pageHeader}</h1>
            {subheader}
          </div>
          <div className="quote-wrapper col-lg-6 mt-4 mt-md-0">
              <ContactForm />
          </div>
        </div>
      </div>
    </div>
  </header>
)

Header.propTypes = {
  pageHeader: PropTypes.string,
  className: PropTypes.string,
  subheader: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
}

Header.defaultProps = {
  pageHeader: ``,
  className: ``,
  subheader: ``
}

export default Header
