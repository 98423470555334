import React from "react"
import SEO from "../components/seo"
import Header from "../components/header"
import { useStaticQuery, graphql } from "gatsby"
import Checkmark from "../images/checkmark.svg"
import { getUrlParams} from '../helpers'
 

const subheader = <h3 className='text-white mt-2 mb-2'>At Ketamine Solution Centers, we apply clinically proven IV ketamine therapy for treatment of depression, anxiety, PTSD and other ailments. Contact us today for a free consultation and start changing your life for the better!</h3>
const CheckMark = () => <><img style={{ width: 20, paddingBottom: '5px', marginRight: 5 }} src={Checkmark} alt='checkmark' /></>

const dynamicHeader = (pageHeader) => {
  if (typeof window !== `undefined`) {
  const headline =  getUrlParams(window.location.search).headline
  let header
    if(typeof headline !== `undefined`) {
      let headerArr = headline.toString().split(" ")
      let headerCap = []
      headerArr.forEach((el) => headerCap.push(el.charAt(0).toUpperCase() + el.substring(1)))
      header = headerCap.join(" ")
    }else {
      header = pageHeader
    }
  return header
  }
}


const HomePage = () => {
  const data = useStaticQuery(graphql`
  query SiteTitleQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`)
  return (
    <>
      <SEO title="Home" />
      <Header
        siteTitle={data.site.siteMetadata.title}
        className='hero-wrapper'
        pageHeader={dynamicHeader('Ketamine Solutions for Depression, Anxiety, PTSD, Pain and more.')}
        subheader={subheader}
      />
      <section className="section-theming bg-white" id="treatment">
        <h2 className="is-purple p-4 header-allignment" >Clinically Proven IV Treatment for Depression, Anxiety, PTSD and more.</h2>
        <div className="container p-4">
          <div className="row">
            <p className="col-sm">
              IV Solution and Ketamine Centers offer clinically proven customized intravenous ketamine therapies in state-of-the-art medical facilities. Experience life-changing therapy in private,
              confidential settings with anesthesiologists, registered nurses, and skilled staff onsite.
              </p>
            <p className="col-sm">We bring this proven therapy to patients in a safe and comfortable outpatient setting,
              providing ketamine infusion therapy services using effective protocols in accordance with the highest industry standards. Our team is committed to providing the best patient outcomes
              to improve quality of life and well-being.
              </p>
          </div>
        </div>
      </section>
      <section className="section-theming bg-white">
        <div className="container">
          <div className="row">
            <div className="col-sm pb-4" style={{ background: '#DDE2F1' }}>
              <h2 className="is-purple p-4 header-allignment">Conditions we treat</h2>
              <p className="pl-4 pr-4">
                Our premier clinic offers innovative and effective intravenous medical treatment for a wide range of treatment-resistant neurological conditions.
                As the top ketamine treatment center in Las Vegas, you can expect state of the art care from a team of experienced medical professionals and physicians.
              </p>
            </div>
            <div className="col-sm pb-4" style={{ background: '#E8EAF0' }}>
              <h2 className="is-purple p-4 header-allignment">We can help with:</h2>
              <table className="is-purple conditions">
                <tbody>
                  <tr>
                    <td className="pr-4"><CheckMark />Depression</td>
                    <td><CheckMark />Fibromyalgia</td>
                  </tr>
                  <tr>
                    <td className="pr-4"><CheckMark />Anxiety</td>
                    <td><CheckMark />Chronic Pain</td>
                  </tr>
                  <tr>
                    <td className="pr-4"><CheckMark />PTSD</td>
                    <td><CheckMark />OCD</td>
                  </tr>
                  <tr><td className="pt-3" style={{ textAlign: 'center' }}>And more…</td></tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default HomePage
